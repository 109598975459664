<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'GuardGroups' }"
              >群組保護</b-breadcrumb-item
            >
            <b-breadcrumb-item active>衛兵狀態列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <b-overlay :show="showLoading" rounded="sm">
        <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">衛兵狀態列表</h4>

        <div class="row d-flex justify-content-between align-items-center">
          <div class="col-12 col-xl-4 d-flex my-3">
            <h6 class="mb-2 mb-xl-0 font-weight-bold">狀態</h6>
            <b-form-checkbox
              v-for="i in statusOptions"
              v-model="selectedStatus"
              :value="i.value"
              :key="i.value"
              class="ml-2"
              @change="fetchGuards"
            >
              {{ i.text }}
            </b-form-checkbox>
          </div>

          <div
            class="
              col-12 col-xl-6
              d-flex
              flex-column flex-xl-row
              align-items-start align-items-xl-center
              my-3
            "
          >
            <b-input-group class="ml-0 ml-xl-4">
              <b-form-input
                placeholder="搜尋姓名／手機"
                v-model="keyword"
                v-on:keyup.enter="fetchGuards()"
              ></b-form-input>
              <b-input-group-append>
                <b-button @click="fetchGuards()"
                  ><i class="fa fa-search"></i
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <b-table responsive striped hover :items="guards" :fields="fields">
          <template #head(report_at)>
            <div class="d-flex">
              <span
                class="d-flex cursor-pointer"
                @click="handleSort('report_at')"
              >
                回報狀態時間
                <sort-caret
                  :status="
                    sortBy === 'report_at'
                      ? sortDesc
                        ? SORT_CARET_STATUS.DESC
                        : SORT_CARET_STATUS.ASC
                      : SORT_CARET_STATUS.INIT
                  "
                ></sort-caret>
              </span>
            </div>
          </template>
          <template #head(event_at)>
            <div class="d-flex">
              <span
                class="d-flex cursor-pointer"
                @click="handleSort('event_at')"
              >
                回報事件時間
                <sort-caret
                  :status="
                    sortBy === 'event_at'
                      ? sortDesc
                        ? SORT_CARET_STATUS.DESC
                        : SORT_CARET_STATUS.ASC
                      : SORT_CARET_STATUS.INIT
                  "
                ></sort-caret>
              </span>
            </div>
          </template>
          <template #head(team)>
            <div class="d-flex">
              <span
                class="d-flex cursor-pointer"
                @click="handleSort('team')"
              >
                Team
                <sort-caret
                  :status="
                    sortBy === 'team'
                      ? sortDesc
                        ? SORT_CARET_STATUS.DESC
                        : SORT_CARET_STATUS.ASC
                      : SORT_CARET_STATUS.INIT
                  "
                ></sort-caret>
              </span>
            </div>
          </template>
          <template #head(role)>
            <div class="d-flex">
              <span
                class="d-flex cursor-pointer"
                @click="handleSort('role')"
              >
                Role
                <sort-caret
                  :status="
                    sortBy === 'role'
                      ? sortDesc
                        ? SORT_CARET_STATUS.DESC
                        : SORT_CARET_STATUS.ASC
                      : SORT_CARET_STATUS.INIT
                  "
                ></sort-caret>
              </span>
            </div>
          </template>
          <template #cell(name)="data">
            <template v-if="!data.item.isEditing">
              <router-link :to="{ name: 'GuardDetail', params: { guardID: data.item.id, }, }">{{ data.item.display_name }}</router-link>
              <br />
              {{ data.item.display_status }}
            </template>
            <template v-else>
              <b-form-input class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="data.item.display_name"></b-form-input>
              <br />
              <b-form-input class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="data.item.display_status"></b-form-input>
            </template>
          </template>
          <template #cell(avatar_url)="data">
            <template v-if="!data.item.isEditing">
              <b-img-lazy v-bind="mainProps" v-show="!!data.item.avatar_url" :src="data.item.avatar_url" class="rounded-circle"></b-img-lazy>
            </template>
            <template v-else>
              <b-form-input class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="data.item.avatar_url"></b-form-input>
            </template>
          </template>
          <template #cell(guard_status)="data">
            <b-badge :variant="statusEnum(data.item.guard_status).variant">{{
              statusEnum(data.item.guard_status).text
            }}</b-badge>
          </template>
          <template #cell(report_at)="data">
            {{ formatDate(data.item.report_at) }}
          </template>
          <template #cell(role)="data">
            <template v-if="!data.item.isEditing">
              {{ data.item.role }}
            </template>
            <template v-else>
              <b-form-input class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="data.item.role" :readonly="!data.item.isEditing"></b-form-input>
            </template>
          </template>
          <template #cell(team)="data">
            <template v-if="!data.item.isEditing">
              {{ data.item.team }}
            </template>
            <template v-else>
              <b-form-input class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="data.item.team" :readonly="!data.item.isEditing"></b-form-input>
            </template>
          </template>
          <template #cell(actions)="data">
            <b-button
              v-if="data.item.isEditing"
              variant="primary"
              class="ml-2"
              size="md"
              @click="updateGuardReport(data.item)"
              >確定更新
            </b-button>

            <b-button
              v-if="! data.item.isEditing"
              variant="danger"
              class="ml-2"
              size="md"
              @click="() => data.item.isEditing = true"
              >重設</b-button
            >
          </template>
        </b-table>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import guardApi from "../../../apis/guard";
import { mapState } from "vuex";
import RoleChecker from "@/utils/RoleChecker";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import moment from "moment";
import SortCaret, {
  SORT_CARET_STATUS,
} from "@/components/SortCaret.vue";

export default {
  components: {
    SortCaret,
  },
  data() {
    return {
      SORT_CARET_STATUS,
      statusOptions: [
        { text: "正常", value: "normal" },
        { text: "逾期(30分鐘)", value: "overdue" },
      ],
      selectedStatus: [],
      consts: consts,
      showLoading: true,
      mainProps: {
        blank: true,
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "m1",
      },
      fields: [
        {
          key: "guard_status",
          label: "狀態",
        },
        {
          key: "avatar_url",
          label: "頭像",
        },
        {
          key: "name",
          label: "姓名",
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "report_at",
          label: "回報狀態時間",
        },
        {
          key: "event_at",
          label: "回報事件時間",
        },
        {
          key: "group_count",
          label: "群組數目",
        },
        {
          key: "Team",
          label: "Team",
        },
        {
          key: "Role",
          label: "Role",
        },
        { key: "actions", label: "功能" },
      ],
      guards: [],
      keyword: null,
      sortBy: "",
      sortDesc: false,
    };
  },
  mounted() {
    this.selectedStatus = this.statusOptions.map((x) => {
      return x.value;
    });
    this.fetchGuards();
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  methods: {
    async fetchGuards() {
      this.showLoading = true;

      try {
        if (this.selectedStatus.length == 0) {
          this.selectedStatus = [this.statusOptions[0].value];
        }

        const params = {};
        if (this.keyword) params.keyword = this.keyword;
        if (!this.sortDesc) params.asc = "";
        if (this.sortBy) params.sort_by = this.sortBy;

        params.guard_status = this.selectedStatus;

        const { data } = await guardApi.getGuardStatus(params);
        data.data.forEach(guard => guard.isEditing = false)
        this.guards = data.data;
      } catch (error) {
        console.error(error);
        alert("取得群組資料錯誤");
      }
      this.showLoading = false;
    },
    statusEnum(status) {
      if (status == 'normal') {
        return {
          text: "正常",
          variant: "success",
        };
      } else if(status=='overdue') {
        return {
          text: "逾期(15分鐘)",
          variant: "danger",
        };
      }
    },
    checkRole(roles) {
      const checker = new RoleChecker();
      return checker.check(roles);
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm");
      }
    },
    async updateGuardReport(guard) {
      this.showLoading = true;
      try {
        let response = await guardApi.updateGuardReport(guard.guard_report_id, {
          team: guard.team,
          role: guard.role,
          display_name: guard.display_name,
          display_status: guard.display_status,
          avatar_url: guard.avatar_url,
        });

        if (response.status !== 200) {
          alert("更新錯誤");
        } else {
          await this.fetchGuards();
        }
      } catch (error) {
        console.log(error)
        alert("更新錯誤");
      }
      this.showLoading = false;
    },
    handleSort(sortBy) {
      if (this.sortBy === sortBy) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = sortBy;
      }
      this.fetchGuards();
    },
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.5;
}
</style>
