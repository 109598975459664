<template>
  <span
    v-if="status === SORT_CARET_STATUS.INIT"
    class="d-flex flex-column position-relative"
  >
    <BIconCaretUpFill size="sm" style="position: absolute; top: -5px" />
    <BIconCaretDownFill
      icon="caret-down-fill"
      size="sm"
      style="position: relative; top: 5px"
    />
  </span>

  <span
    v-else-if="status === SORT_CARET_STATUS.DESC"
    class="d-flex flex-column position-relative"
  >
    <BIconCaretUpFill size="sm" style="position: absolute; top: -5px" />
    <BIconCaretDownFill
      class="active"
      size="sm"
      style="position: relative; top: 5px"
    ></BIconCaretDownFill>
  </span>

  <span
    v-else-if="status === SORT_CARET_STATUS.ASC"
    class="d-flex flex-column position-relative"
  >
    <BIconCaretUpFill
      class="active"
      size="sm"
      style="position: absolute; top: -5px"
    ></BIconCaretUpFill>
    <BIconCaretDownFill
      icon="caret-down-fill"
      size="sm"
      style="position: relative; top: 5px"
    />
  </span>
</template>

<script>
import { BIconCaretUpFill, BIconCaretDownFill } from "bootstrap-vue";

export const SORT_CARET_STATUS = {
  INIT: "INIT",
  DESC: "DESC",
  ASC: "ASC",
};

export default {
  components: {
    BIconCaretUpFill,
    BIconCaretDownFill,
  },
  props: ["status"],
  data() {
    return {
      SORT_CARET_STATUS,
    };
  },
};
</script>

<style lang="scss">
.b-icon {
  color: #cdd6dc;
  &.active {
    color: #de006f;
  }
}
</style>
